(function ($) {
  Drupal.behaviors.headerOffersBannerFormatterV1 = {
    attached: false,

    attach: function (context) {
      var $body = $('body');

      if (this.attached) {
        return;
      }
      this.attached = true;
      var $formatter = $('.js-header-offers-banner-formatter-v1', context);
      var $carousel = $('.js-header-offers-banner-formatter-carousel', $formatter);
      var $slides = $carousel.find('.header-offers-banner-formatter__carousel-item');
      var $close = $('.js-header-offers-banner-formatter__close', $formatter);
      var $arrow = $('.js-header-offers-banner-formatter-carousel-arrow', $carousel);
      var slideIndex = 1;
      var timer = null;
      var autoplay = $carousel.data('slides-autoplay');
      var speed = parseInt($carousel.data('speed'));

      showSlides(slideIndex);

      if ($.cookie('hide_header_offer_banner')) {
        $body.addClass('gnav-offers-hidden');

        return;
      }
      $carousel.removeClass('not-initialized');
      // Next/previous controls
      $arrow.once().on('click', function () {
        var newIndex = $(this).data('index');

        clearTimeout(timer);
        showSlides((slideIndex += newIndex));
      });

      $close.on('click', function () {
        $.cookie('hide_header_offer_banner', '1', { path: '/' });
        $(document).trigger('hide_header_offer_banner');
        $body.addClass('gnav-offers-hidden');
      });

      function showSlides(n) {
        if (n === undefined) {
          n = ++slideIndex;
        }
        if (n > $slides.length) {
          slideIndex = 1;
        }
        if (n < 1) {
          slideIndex = $slides.length;
        }
        $slides.removeClass('item-active');
        $slides.eq(slideIndex - 1).addClass('item-active');
        if (autoplay) {
          timer = setTimeout(showSlides, speed);
        }
      }
    }
  };
})(jQuery);
